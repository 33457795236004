<template>
  <!-- 勘探新增数据模块 -->
  <div class="exploeincrease bgblue">
    <!-- 用户信息 -->
    <div class="userInformation">
      <el-container style="width: 100%; ">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>用户信息</el-header
        >
        <el-main>
          <div class="search" style="margin-right: 1px">
            <el-select v-model="value" placeholder="请选择用户">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button type="primary" style="margin-left: 10px" @click="choose"
              >选择</el-button
            >
          </div>
          <el-dialog
            title="请选择客户"
            :visible.sync="outerVisible"
            width="60%"
          >
            <!-- 选择弹框部分 -->
            <div class="select">
              <span style="font-size: 16px">客户名称:&nbsp;&nbsp;</span
              ><el-select v-model="value" placeholder="请选择客户名称">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-table
                :data="tabData"
                border
                style="width: 100%"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="120px"
                >
                </el-table-column>
                <el-table-column
                  prop="reconnaissanceName"
                  label="勘探人员"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="customerName"
                  label="客户名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="mobile" label="手机号" align="center">
                </el-table-column>
                <el-table-column prop="address" label="用户地址" align="center">
                </el-table-column>
              </el-table>
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.currPage"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </el-dialog>
          <div class="information">
            <el-descriptions title="">
              <el-descriptions-item label="用户编号"
                >K000869711</el-descriptions-item
              >
              <el-descriptions-item label="用户姓名"
                >陈国芳</el-descriptions-item
              >
              <el-descriptions-item label="手机号"
                >18709885078</el-descriptions-item
              >
              <el-descriptions-item label="性别"> 未知 </el-descriptions-item>
              <el-descriptions-item label="证件类型"
                >身份证</el-descriptions-item
              >
              <el-descriptions-item label="身份证"
                >341281196610104663</el-descriptions-item
              >
              <el-descriptions-item label="代理商编号"
                >C0004249</el-descriptions-item
              >
              <el-descriptions-item label="代理商名称"
                >亳州市来裕新能源科技有限公司</el-descriptions-item
              >
              <el-descriptions-item label="所属分公司"
                >皖北分公司</el-descriptions-item
              >
              <el-descriptions-item label="用户住址"></el-descriptions-item>
            </el-descriptions>
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 勘探基本信息 -->
    <div
      style="
        background-color: #fff;
        float: left;
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
      class="expoleInformation"
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>勘探基本信息</el-header
        >
        <el-main>
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            style="position: relative"
            label-width="150px"
            :header-cell-style="{
              'font-size': '16px',
            }"
          >
            <el-row>
              <el-col :span="8">
               <el-form-item label="勘探编号">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="勘探时间">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="电站类型">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="勘探方式">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8">
                <el-form-item label="勘探人员">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="联系方式">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"
                ><el-form-item label="不拆迁图片迁移">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="合作类型" label-width="150px">
                  <el-select v-model="formInline.region" placeholder="请选择">
                    <el-option
                      v-for="item in cooperationType"
                      :key="item.region"
                      :label="item.label"
                      :value="item.region"
                    >
                    </el-option>
                  </el-select> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="组件规格">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"
                ><el-form-item label="单片功率(W)">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="方案块数(块)">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="方案总功率(W)">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"
                ><el-form-item label="系统功率">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="安装编号">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="创建账号">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"
                ><el-form-item label="基准位置">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="销售人员姓名">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="8"
                ><el-form-item label="销售人员手机号">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"
                ><el-form-item label="勘探位置">
                  <el-input
                    v-model="formInline.user"
                    placeholder="请输入"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
          </el-form>
        </el-main>
      </el-container>
    </div>
    <!-- 房屋照片 -->
    <div
      class="roofPicture"
      style="
        background-color: #fff;
        float: left;
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>房屋照片</el-header
        >
        <el-main>
          <!-- 正大门照片 -->

          <div class="pictureTop">
            <div class="title">正大门照片(1/10)</div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item label="正大门照片 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 37px;
                  font-size: 12px;
                  color: #666;
                "
              >
                示例图片
              </p>
              <el-form-item label="其他图片 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-form>
          </div>
          <!-- 主屋照片 -->
          <div class="pictureTop">
            <div class="title">主房屋全景照片（可看清房屋层数）(1/10)</div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item label="房屋全景照片 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 37px;
                  font-size: 12px;
                  color: #666;
                "
              >
                示例图片
              </p>
              <el-form-item label="其他图片">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-form>
          </div>
          <!-- 主屋屋顶照片 -->
          <div class="pictureTop">
            <div class="title">主房屋屋顶照片（可看清房屋屋顶类型）(1/10)</div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item label="房屋全景照片 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 37px;
                  font-size: 12px;
                  color: #666;
                "
              >
                示例图片
              </p>
              <el-form-item label="其他图片">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-form>
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 其他照片信息 -->
    <div
      class="otherPicture"
      style="
        background-color: #fff;
        float: left;
      
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>其他照片信息</el-header
        >
        <el-main>
          <!-- 正大门照片 -->

          <div class="pictureTop">
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item label="方位角照片 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 37px;
                  font-size: 12px;
                  color: #666;
                "
              >
                示例图片
              </p>
              <el-form-item label="其他照片 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <el-form-item label="方位角角度 :">
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
            </el-form>
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 方案信息 -->
    <div
      class="tipInformation"
      style="
        background-color: #fff;
        float: left;
       
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>方案信息</el-header
        >
        <el-main>
          <!-- 屋顶 -->
          <div class="roof">
            <p>屋顶1</p>
          </div>
          <!-- 自动生成方案 -->
          <div class="tipTop">
            <!-- 自动生成表格部分 -->
            <div class="tiptopTitle">
              <p>自动生成方案</p>
            </div>
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="position: relative"
              label-width="120px"
            >
              <el-row>
                <el-col :span="8">
                  <el-form-item label="屋顶类型">
                    <el-input
                      style="width: 215px"
                      v-model="formInline.user"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="屋顶形状">
                    <el-input
                      style="width: 215px"
                      v-model="formInline.user"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="安装方式">
                    <el-input
                      style="width: 215px"
                      v-model="formInline.user"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="长/a(米)">
                    <el-input
                      style="width: 215px"
                      v-model="formInline.user"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="宽/b(米)">
                    <el-input
                      style="width: 215px"
                      v-model="formInline.user"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8"> </el-col>
              </el-row>
            </el-form>
          </div>
          <!-- 组件排布方案 -->
          <div class="top-bottom">
            <div class="tiptopTitle">
              <p>组件排布方案</p>
            </div>
            <!-- 屋顶组件排布图 -->
            <div class="roofpic">
              <p class="rooftitle">屋顶1组件排布图</p>
              <div class="picture">
                <el-row v-for="item in 3" :key="item.id">
                  <el-col :span="3" v-for="item in 8" :key="item.id"
                    ><div
                      style="
                        border: 1px solid #737373;
                        width: 30px;
                        height: 80px;
                      "
                    ></div
                  ></el-col>
                </el-row>
              </div>
              <div style="margin: 20px; margin-bottom: 40px; font-size: 12px">
                <p>说明:</p>
                <p>1. 本屋顶可安装56块660W光伏组件</p>
                <p>2. 组件尺寸：2404 * 1323 * 35 (mm)</p>
                <p>
                  3.
                  本图仅供参考，如现场实际情况与图纸不符，请及时与技术人员联系，安装方式以技术人员最终确认为准。
                </p>
              </div>
              <div class="tiptopTitle">
                <p>现场照片(8/15)</p>
              </div>
            </div>
          </div>
          <div class="pic">
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-row>
                <el-col :span="4">
                  <el-form-item label="东面照片 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="东南面照片 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="南面照片:">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="西南面照片:">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="西面照片:">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="房屋南立面照片 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4"> 
                  <el-form-item label="房屋内部结构照片 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="房屋内部结构照片 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                 </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="其他照片 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                 </el-col>
              </el-row>
              <div class="tiptopTitle">
                <p>现场视频(1/2)</p>
                
              </div>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="周围环境及屋面视频 :">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                 </el-col>
              </el-row>
            </el-form>
          
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 设计图纸 -->
    <div
      class="designPicture"
      style="
        background-color: #fff;
        float: left;
       padding: 20px;
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>设计图纸</el-header
        >
        <el-main>
          <!-- 设计图片文件 -->
          <p style="color: #555555; margin-left: 20px;">设计图纸文件:</p>
          <div class="pictureTop" style="margin-left: 115px;">
        
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
            >
          <el-row :gutter="20">
            <el-col :span="4">
              <el-form-item >
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 30px;
                  font-size: 12px;
                  color: #666;
                "
              >
                请上传平面图文件
              </p>
            </el-col>
            <el-col :span="4">
              <el-form-item >
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 30px;
                  font-size: 12px;
                  color: #666;
                "
              >
                请上传平面图文件
              </p>
            </el-col>
            <el-col :span="4">
              <el-form-item >
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 30px;
                  font-size: 12px;
                  color: #666;
                "
              >
                请上传平面图文件
              </p>
            </el-col>
            <el-col :span="4">
              <el-form-item >
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 37px;
                  font-size: 12px;
                  color: #666;
                "
              >
                请上传平面图文件
              </p>
            </el-col>
            <el-col :span="4">
              <el-form-item >
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :auto-upload="false"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-form-item>
              <p
                style="
                  margin-top: -10px;
                  margin-left: 37px;
                  font-size: 12px;
                  color: #666;
                "
              >
                请上传平面图文件
              </p>
            </el-col>
          </el-row>
             
            
            </el-form>
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 备注 -->
    <div
      class="remarkPicture"
      style="
        background-color: #fff;
        float: left;
        padding: 20px;
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>备注</el-header
        >
        <el-main>
          <!-- 备注 -->
           <div class="remark" width="1500px">
           
           </div>

        </el-main>
      </el-container>
    </div>
    <!-- 勘探记录 -->
    <div
      class="expolePicture"
      style="
        background-color: #fff;
        float: left;
      
        width: 100%;
        border-radius: 10px;
        margin: 10px 5px;
      "
    >
      <el-container style="width: 100%; height: 100%">
        <el-header
          style="font-size: 18px; letter-spacing: 0.1em; font-weight: 500"
          ><span></span>勘探记录</el-header
        >
        <el-main>
          <!-- 表格 -->
       <div class="table">
        <el-table
      :data="tableData"
      style="width: 100%"
      center
      >
      <el-table-column
        prop="date"
        label="流程状态"
        width="250">
      </el-table-column>
      <el-table-column
        prop="name"
        label="部门/角色"
        width="250">
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作人"
        width="250"
        >
      </el-table-column>
      <el-table-column
        prop="date"
        label="备注"
        width="250">
      </el-table-column>
      <el-table-column
        prop="name"
        label="操作时间"
        width="250">
      </el-table-column>
      <el-table-column
        prop="address"
        label="操作图片"
        width="250"
        >
      </el-table-column>
    </el-table>
       </div>

        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      cooperationType: [
        {
          region: "选项1",
          label: "黄金糕",
        },
        {
          region: "选项2",
          label: "双皮奶",
        },
        {
          region: "选项3",
          label: "蚵仔煎",
        },
        {
          region: "选项4",
          label: "龙须面",
        },
        {
          region: "选项5",
          label: "北京烤鸭",
        },
      ],
      tableData: [{
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-08',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-06',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-07',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }],
      tabData: [],
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          businessType: "",
          accountItemName: "",
        },
      },

      formInline: {
        user: "",
        region: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,
      value: "",
      formLabelAlign: {},
      outerVisible: false,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    choose() {
      this.outerVisible = true;
      this.getData();
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getData();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getData();
    },

    getData() {
      this.$http.post("/houseCustomer/list", this.queryInfo).then((res) => {
        if (res.data.code == 200) {
          this.total = res.data.data.count;
          this.tabData = res.data.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" >
.exploeincrease {
  overflow: hidden;
  padding: 5px;
  box-sizing: border-box;
  .el-dialog__title {
    font-weight: 800 !important;
    font-size: 16px !important;
  }
  ::v-deep .el-descriptions--small {
    font-size: 16px;
  }

  ::v-deep
    .el-descriptions__body
    .el-descriptions__table
    .el-descriptions-item__cell {
    line-height: 1.9;
  }
  ::v-deep .el-form-item--small .el-form-item__label {
    font-size: 16px;
  }
  ::v-deep input.el-input__inner {
    width: 200px;
   
  }
  .el-input--suffix .el-input__inner {
    padding-right: 15px !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  .userInformation {
    width: 100%;
    margin: 10px 5px;
    background-color: #fff;
    border-radius: 10px;

    .select {
      margin-top: -18px;
      padding: 20px;
      box-sizing: border-box;
      border-top: 1px solid #ccc;
    }
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }

    .el-main {
      .search {
        height: 20%;
        margin-top: 15px;
        box-sizing: border-box;
        margin-left: 40px;
      }
      .information {
        margin: 5px 45px;
        box-sizing: border-box;
      }
    }
  }
  .expoleInformation {
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }
    .el-main {
      margin: 20px;
    }
  }
  .roofPicture {
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }
    .el-main {
      .pictureTop {
        height: 490px;
        width: 70%;
        margin-left: 40px;
        .title {
          height: 45px;
          line-height: 45px;
          box-shadow: 0px 6px 12px -13px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  // 其他图片
  .otherPicture {
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }
    .el-main {
      .pictureTop {
        margin-top: 20px;
        width: 70%;
        margin-left: 40px;
        .title {
          height: 45px;
          line-height: 45px;
          box-shadow: 0px 6px 12px -13px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  //方案信息
  .tipInformation {
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }

    .roof {
      background-color: #e3f5fd;
      margin: 20px;
      height: 50px;
      border-radius: 5px;
      p {
        margin-left: 20px;
        font-size: 14px;
        line-height: 50px;
        font-weight: 500;
      }
    }
    .tiptopTitle {
      margin: 20px;
      border-bottom: 1px solid #efefef;
    }
    .roofpic {
      .rooftitle {
        text-align: center;
      }

      .picture {
        width: 240px;
        height: 210px;
        background-color: black;
        overflow: hidden;
        margin: 20px auto;
      }
    }
    .el-main {
      .pic {
        margin-top: 20px;
        width: 100%;
        margin-left: 40px;
        .title {
          height: 45px;
          line-height: 45px;
          box-shadow: 0px 6px 12px -13px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  //设计图纸
  .designPicture {
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }
    .el-main {
      .pictureTop {
       
        width: 70%;
        margin-left: 40px;
        .title {
          height: 45px;
          line-height: 45px;
          box-shadow: 0px 6px 12px -13px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  //备注
  .remarkPicture {
   
    .el-header {
      box-shadow: 0px 11px 10px -13px #ccc;
      line-height: 60px;
      position: relative;
      span {
        display: block;
        width: 5px;
        height: 25px;
        position: absolute;
        top: 18px;
        left: 10px;
        background-color: rgb(2, 167, 240);
      }
    }
    .el-main {
      .remark {
        border: 1px solid #ccc;
        margin-right: 20px;
        margin-top: 20px;
        width: 90%;
        height: 200px;
        margin-left: 40px;
        .title {
          height: 45px;
          line-height: 45px;
          box-shadow: 0px 6px 12px -13px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  //勘探记录
  .expolePicture {
     
   .el-header {
     box-shadow: 0px 11px 10px -13px #ccc;
     line-height: 60px;
     position: relative;
     span {
       display: block;
       width: 5px;
       height: 25px;
       position: absolute;
       top: 18px;
       left: 10px;
       background-color: rgb(2, 167, 240);
     }
   }
   .el-main {
    padding-left: 40px;
  
   }
 }
}
</style>